//packages/front-end/src/components/ArtistDetail.tsx

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Button, Box, Grid, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingPage } from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import ArtistsService from '../services/Artists';
import { Event as EventType } from '../types';
import EventMiniCard from './EventMiniCard';
import { UsersIcon } from '../views/admin/Users';
import {
  TwitchIcon,
  SoundCloudIcon,
  MixCloudIcon,
  SpotifyIcon,
  VimeoIcon,
  TikTokIcon,
} from './SocialIcons';
import { YouTube } from '@mui/icons-material';
import { initGoogleAds } from './Utils';

const ArtistDetail = () => {
  const { id }: { id?: number } = useParams();
  const navigate = useNavigate();
  const [artist, setArtist] = React.useState<any>(null);
  const [upcomingEvents, setUpcomingEvents] = React.useState<any[]>([]);
  const [pastEvents, setPastEvents] = React.useState<any[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchArtist = async () => {
      try {
        const artistData = await ArtistsService.getArtist(id);
        if (!artistData.data) {
          navigate('/');
          return;
        }
        setArtist(artistData.data);
        setUpcomingEvents(
          artistData.data.events
            .filter(
              (event: EventType) => new Date(event.datetime_start) > new Date(),
            )
            .sort(
              (a: EventType, b: EventType) =>
                new Date(a.datetime_start).getTime() -
                new Date(b.datetime_start).getTime(),
            ),
        );

        setPastEvents(
          artistData.data.events
            .filter(
              (event: EventType) => new Date(event.datetime_end) < new Date(),
            )
            .sort(
              (a: EventType, b: EventType) =>
                new Date(b.datetime_start).getTime() -
                new Date(a.datetime_start).getTime(),
            ),
        );
      } catch (error) {
        console.error('Failed to fetch artist:', error);
        navigate('/');
      }
    };
    fetchArtist();
  }, [id, navigate]);

  useEffect(initGoogleAds, []);

  if (!artist) {
    return (
      <Box>
        <LoadingPage
          loadingPrimary="Loading..."
          loadingSecondary="Please wait while we load the artist details!"
        />
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box
            sx={{
              mx: 'auto',
              my: 1,
              width: '100%',
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            {artist.image_url ? (
              <img
                src={artist.image_url}
                alt={artist.name}
                style={{ width: '100%' }}
              />
            ) : (
              <Box
                py={6}
                display="flex"
                flexDirection="column"
                alignContent={'center'}
                alignItems={'center'}
              >
                <UsersIcon style={{ width: 120, height: 120 }} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={9} alignContent={'center'} alignItems={'center'}>
          <Typography variant="h1" mb={1}>
            {artist.name}
          </Typography>
          {artist.website && (
            <Typography mb={1}>
              <Link target="_blank" color="ctaColor.main" href={artist.website}>
                {artist.website}
              </Link>
            </Typography>
          )}
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {artist.soundcloud_username && (
              <Link
                target="_blank"
                href={`https://www.soundcloud.com/${artist.soundcloud_username}`}
                color="ctaColor.main"
              >
                <SoundCloudIcon />
              </Link>
            )}
            {artist.twitch_username && (
              <Link
                target="_blank"
                href={`https://www.twitch.com/${artist.twitch_username}`}
                color="ctaColor.main"
              >
                <TwitchIcon />
              </Link>
            )}
            {artist.mixcloud_username && (
              <Link
                target="_blank"
                href={`https://www.mixcloud.com/${artist.mixcloud_username}`}
                color="ctaColor.main"
              >
                <MixCloudIcon />
              </Link>
            )}
            {artist.spotify_username && (
              <Link
                target="_blank"
                href={`https://open.spotify.com/user/${artist.spotify_username}`}
                color="ctaColor.main"
              >
                <SpotifyIcon />
              </Link>
            )}
            {artist.youtube_username && (
              <Link
                target="_blank"
                href={`https://www.youtube.com/@${artist.youtube_username}`}
                color="ctaColor.main"
              >
                <YouTube />
              </Link>
            )}
            {artist.vimeo_username && (
              <Link
                target="_blank"
                href={`https://vimeo.com/${artist.vimeo_username}`}
                color="ctaColor.main"
              >
                <VimeoIcon />
              </Link>
            )}
            {artist.tiktok_username && (
              <Link
                target="_blank"
                href={`https://www.tiktok.com/@${artist.tiktok_username}`}
                color="ctaColor.main"
              >
                <TikTokIcon />
              </Link>
            )}

            {artist.twitter_username && (
              <Link
                target="_blank"
                href={`https://www.twitter.com/${artist.twitter_username}`}
                color="ctaColor.main"
              >
                <TwitterIcon />
              </Link>
            )}
            {artist.instagram_username && (
              <Link
                target="_blank"
                href={`https://www.instagram.com/${artist.instagram_username}`}
                color="ctaColor.main"
              >
                <InstagramIcon />
              </Link>
            )}
            {artist.facebook_username && (
              <Link
                target="_blank"
                href={`https://www.facebook.com/${artist.facebook_username}`}
                color="ctaColor.main"
              >
                <FacebookIcon />
              </Link>
            )}
          </Box>
          <Typography variant="body1">{artist.description}</Typography>
        </Grid>
      </Grid>
      <Box my={2}>
        <ins
          className="ADSENSE"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2902939122125433"
          data-ad-slot="3356509899"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Box>
      {upcomingEvents.length > 0 && (
        <>
          <Typography variant="h4">Upcoming Events</Typography>
          {upcomingEvents.map((event) => {
            event.artists = [artist];
            return <EventMiniCard key={event.id} event={event} />;
          })}
        </>
      )}

      {pastEvents.length > 0 && (
        <>
          <Typography variant="h4">Past Events</Typography>
          {pastEvents.map((event) => {
            event.artists = [artist];
            return <EventMiniCard key={event.id} event={event} />;
          })}
        </>
      )}
      <Button
        variant="contained"
        href="/artists"
        component={Link}
        p={1}
        mt={'8px'}
        sx={{
          backgroundColor: (theme) => theme.palette.ctaColor?.main,
          color: '#fff',
          fontFamily: '"kon-tiki-aloha-jf", sans-serif',
          mb: 2,
          fontSize: '1.2em',
          width: '100%',
        }}
      >
        View Other Artists
      </Button>
    </Box>
  );
};

export default ArtistDetail;
